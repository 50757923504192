@charset "UTF-8";
@import "_setting";
@import "_mixin";
html,body{
	height: 100%;
}
body#top{
	#page{
		min-width: 1200px;
		margin: 0 auto;
		padding: 0;
	}
	#primary{
		display: none;
	}
	#main-img{
		position: relative;
		margin-top: 80px;
		// h1{
		// 	position: absolute;
		// 	top: 50%;
		// 	right: 50px;
		// 	margin: -80px 0 0 0;
		// }
		h1{
			margin: 0;
			line-height: 0;
			font-size: 0;
		}
		.scrl{
			position: absolute;
			bottom: 250px;
			left: 50%;
			-ms-transform: translate(-50%, 0);
			-webkit-transform: translate(-50%, 0);
			transform: translate(-50%, 0);
			z-index: 10;
			font-size: 12px;
			font-size: 1.2rem;
			color: #1a5a97;
		}
		.scrl-line{
			position: absolute;
			bottom: 170px;
			left: 50%;
			width: 1px;
			height: 70px;
			background: #1a5a97;
			-webkit-animation: scrlani 1.5s cubic-bezier(1,0,0,1) infinite;
			animation: scrlani 1.5s cubic-bezier(1,0,0,1) infinite;
		}
	}
	#slider{
		position: absolute;
		bottom: 0;
		left: 50%;
		-ms-transform: translate(-50%, 0);
		-webkit-transform: translate(-50%, 0);
		transform: translate(-50%, 0);
		background: #fff;
		max-width: 1150px;
		padding: 26px 76px;
		opacity: 0;
		.slick-prev,
		.slick-next{

		}
		.slick-prev:before,
		.slick-next:before{
			color: #2e4c69;
			font-size: 30px;
			opacity: 1;
			filter: alpha(opacity=100);
			cursor: pointer;
		}
		.slick-slide{
			padding: 0 10px;
		}
	}
	#slider.slick-slider{
		opacity: 1;
	}
	#coution{
		border: 1px solid #282828;
		background: #fafafa;
		width: 900px;
		margin: 50px auto;
		padding: 40px 36px;
		strong,p{
			margin: 0;
		}
		p{
			margin-top: 20px;
			line-height: 1.8;
		}
	}
	.free-note{
		width: 900px;
		margin: 30px auto 30px auto;
		padding: 40px 36px;
		background: #fff;
		a,
		a:link,
		a:visited,
		a:active{
			opacity: 1;
			filter: alpha(opacity=100);
		}
		a:hover{
			opacity: 0.6;
			filter: alpha(opacity=60);
		}
		.free-note-ttl{
			font-size: 20px;
			font-weight: 600;
		}
		.free-note-detail{
			img{
				width: auto;
				height: auto;
			}
		}
		.free-note-img{
			text-align: center;
			img{
				width: auto;
				height: auto;
			}
		}
	}
	.cta-note{
		width: 900px;
		margin: 30px auto 30px auto;
		padding: 40px 36px;
		a,
		a:link,
		a:visited,
		a:active{
			opacity: 1;
			filter: alpha(opacity=100);
		}
		a:hover{
			opacity: 0.6;
			filter: alpha(opacity=60);
		}
		.cta-note-ttl{
			text-align: center;
			font-size: 20px;
			font-weight: 600;
		}
		.cta-note-link{
			position: relative;
			display: block;
			width: 340px;
			margin: 20px auto 0 auto;
			text-align: center;
			color: #fff;
			opacity: 1;
			filter: alpha(opacity=100);
			font-weight: bold;
			padding: 20px 0px;
			font-size: 14px;
			font-size: 1.4rem;
			background: #296966;
			.fas{
				padding-left: 15px;
			}
		}
		.cta-note-img-link{
			text-align: center;
			display: block;
			margin-top: 20px;
			img{
				width: auto;
				height: auto;
			}
		}
	}

	.img-only{
		width: 900px;
		margin: 30px auto 30px auto;
		padding: 40px 36px;
		a,
		a:link,
		a:visited,
		a:active{
			opacity: 1;
			filter: alpha(opacity=100);
		}
		a:hover{
			opacity: 0.6;
			filter: alpha(opacity=60);
		}
		.img-only-img-link{
			text-align: center;
			display: block;
			margin-top: 20px;
			img{
				width: auto;
				height: auto;
			}
		}
	}
	@include media(sp){
		.free-note{
			width: auto;
			margin: 15px auto 15px auto;
			padding: 20px 20px;
			.free-note-ttl{
				font-size: 20px;
				font-weight: 600;
			}
			.free-note-detail{
				img{
					width: auto;
					height: auto;
				}
			}
			.free-note-img{
				text-align: center;
				img{
					width: auto;
					height: auto;
				}
			}
		}
		.cta-note{
			width: auto;
			margin: 15px auto 15px auto;
			padding: 20px 20px;
			.cta-note-ttl{
			}
			.cta-note-link{
				width: 100%;
				.fas{
				}
			}
			.cta-note-img-link{
				img{
				}
			}
		}

		.img-only{
			width: auto;
			margin: 15px auto 15px auto;
			padding: 20px 20px;
			.img-only-img-link{
				img{
				}
			}
		}
	}
}



@include media(sp){

body#top{
	#page{
		min-width: auto;
		margin: 0 auto;
	}
	#main-img{
		margin-top: 45px;
		h1{
		}
		.scrl{
			bottom: 100px;
		}
		.scrl-line{
			bottom: 20px;
		}
	}
	#slider{
		position: static;
		-ms-transform: translate(0, 0);
		-webkit-transform: translate(0, 0);
		transform: translate(0, 0);
		padding: 10px 15px;
		.slick-prev,
		.slick-next{

		}
		.slick-prev:before,
		.slick-next:before{
			color: #2e4c69;
			font-size: 30px;
			opacity: 1;
			filter: alpha(opacity=100);
			cursor: pointer;
		}
		.slick-slide{
			padding: 0 10px;
		}
	}
	#coution-wrap{
		padding: 0 7px;
		#coution{
			border: 1px solid #282828;
			background: #fafafa;
			width: auto;
			margin: 30px auto;
			padding: 20px 20px;
			line-height: 1.3;
			letter-spacing: -1px
			strong,p{
			}
			p{
			}
		}
	}
}
}

@media screen and (max-width: 980px) {

}
@-webkit-keyframes scrlani {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0
    }

    50.1% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 0 100%;
        transform-origin: 0 100%
    }

    to {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 0 100%;
        transform-origin: 0 100%
    }
}

@keyframes scrlani {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0
    }

    50.1% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 0 100%;
        transform-origin: 0 100%
    }

    to {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 0 100%;
        transform-origin: 0 100%
    }
}